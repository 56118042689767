<template>
  <div>
    <!-- 员工管理 -->
    <ch-layout  button page search>
      <template #search>
        <div style="width: 100%;">
          <ch-search
            :render-option="searchOption" :model="searchForm"
            :props="{ labelWidth: '38px'}">
          </ch-search>
        </div>
      </template>
      <template v-slot:button>
        <ch-button
          @click="openAdd"
        >新增</ch-button>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{height: '100%', size: 'mini',}">
          <template v-slot:handle="scope">
            <ch-button type="link" @click="openEdit(scope.row)">编辑</ch-button>
            <ch-button type="link" v-if="scope.row.status === 1" @click="handleSwitch(scope.row.id,0)">启用</ch-button>
            <ch-button type="delete" v-if="scope.row.status === 0" @click="handleSwitch(scope.row.id,1)">停用</ch-button>
            <ch-button type="link" @click="openWatch(scope.row)">查看</ch-button>
            <ch-button type="delete" @click="handleDelete(scope.row)">删除</ch-button>
          </template>
        </ch-table>
      </template>
      <template v-slot:page>
        <ch-page
          type="old" ref="page"
          :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <!-- 查询 编辑 修改 -->
    <user-modal
      type="old" ref="modal"
      :getListFunction="getTableList"
    ></user-modal>
  </div>
</template>
<script>
import {getTreeSelect, handleDelete} from "@/utils/utils";
import UserModal from "./userModal";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {UserModal},
  data() {
    return {
      tableList: [],
      isTableLoading: false,
      treeSelect: {
        userremove: false, //删除
        useredit: false, //编辑
        userquery: false, //查看
        useradd: false, //新增
        userauth: false, //授权
        usersend: false, //发送短信
        userdisabled: false, //禁用
      },

      // 搜索相关
      searchForm: {
        key: 'name',
        value: null,
        deptId: null,
        post: null,
      },
      params: {}
    }
  },
  computed: {
    tableOption: function () {
      return [
        {column: 'type', prop: '', type: 'selection', width: '25px'},
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: 'text', label: '工号', prop: 'account', showOverflowTooltip: true },
        {column: 'text', label: '姓名', prop: 'name', showOverflowTooltip: true},
        {column: 'text', label: '手机号码', prop: 'iphone', showOverflowTooltip: true },
        {column: 'text', label: '部门', prop: 'deptName', showOverflowTooltip: true },
        {column: 'text', label: '岗位', prop: 'postName', showOverflowTooltip: true},
        {column: 'text', label: '角色', prop: 'roleName', showOverflowTooltip: true },
        {column: 'text', label: '入职日期', prop: 'entryTime', showOverflowTooltip: true},
        {column: 'text', label: '账号状态', prop: '_status', showOverflowTooltip: true},
        {column: 'slot', label: '操作', slotName: 'handle', width: '170px', fixed: 'right' }
      ]
    },
    searchOption: function () {
      return [
        {type: 'select',  label: '部门', prop: 'dept', placeholder: "请选择部门",
          name: "deptName", value: "id", option: this.deptOption},
        {type: 'select',  label: '岗位', prop: 'post', placeholder: "请选择岗位",
          name: "postName", value: "postName", option: this.postOption},
        {type: 'search', key: 'key', value: 'value', option: [
            {key: 'name', value: '姓名'},
            {key: 'iphone', value: '手机号'},
          ]},
        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]},
      ]
    },
    ...mapGetters('system', ['deptOption', 'postOption', 'roleOption']),
    ...mapGetters(['userInfo'])
  },
  created() {
    // 获取操作显示权限
    getTreeSelect(this.$route.name).then((res) => {
      this.treeSelect = res
    })
  },
  mounted() {
    this.getTableList();
    this.getDeptOption();
    this.getUserOption();
    this.getPostOption();
    this.getRoleOption();
  },
  methods: {
    ...mapActions({
      getUserOption: 'system/getUserOption', // 获取所有人员
      getPostOption: 'system/getPostOption', // 获取岗位下拉
      getDeptOption: 'system/getDeptOption', // 部门下拉
      getRoleOption: 'system/getRoleOption'
    }),
    // 查询
    searchHandle() {
      let params = {
        ...this.searchForm,
        key: undefined,
        value: undefined
      }
      if(this.searchForm.key) {
        params[this.searchForm.key] = this.searchForm.value
      }
      this.params = params
      this.getTableList(params)
    },
    // 列表数据
    getTableList(searchForm=this.params) {
      this.isTableLoading = true
      this.$refs.page?.getServerList('/hm/staff/list', searchForm).then(res => {
        this.tableList = res.rows.map(item => {
          item._status = item.status === 1 ? '停用' : '启用'
          return item
        })
        this.isTableLoading = false
      }).catch(() => {
        this.isTableLoading = false
      })
    },
    // 打开查看
    openWatch(row) {
      this.$refs.modal?.openWatch(row)
    },
    // 打开增加
    openAdd(){
      this.$refs.modal?.openAdd()
    },
    // 打开修改
    openEdit(row) {
      this.$refs.modal?.openEdit(row)
    },
    // 打开授权
    openAuth(row) {
      this.$refs.modal?.openAuth(row)
    },
    // 切换状态
    handleSwitch(id,status) {
      let url = status === 1 ? '/hm/staff/disable/' : '/hm/staff/enable/'
      this.$curl.get(url+id).then(() => {
        this.getTableList()
        this.$message.success(status !== 1 ? "账号停用成功！" : "账号启用成功！")
      })
    },
    handleDelete(row) {
      handleDelete('/hm/staff/', row.id).then(() => {
        this.getTableList()
        this.getUserOption(true)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
